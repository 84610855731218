<template>
  <div>
    <Close />
    <section class="hero is-fullheight follow-section">
      <div class="hero-body">
        <div class="block">
          <p
            class="is-size-4 has-text-weight-bold mb-4"
            v-html="$t('follow_real_time')"
          ></p>
          <p class="is-size-3 has-text-primary has-text-weight-bold">
            {{ $t(`rooms.${roomId}.name`) }}
          </p>
        </div>
        <div class="block">
          <p class="is-size-6rem main-title">{{ room.free_seats }}</p>
          <p class="is-size-5 main-legend">{{ $t("free_seats") }}</p>
          <LoadingSpinner class="mt-4 is-primary" size="is-large" :class="{'is-invisible': !loading }" />
        </div>
        <div class="block">
          <button
            type="button"
            class="button notifications-toggle is-outlined"
            :class="{ active: hasNotifications }"
            @click="follow"
          >
            <span class="icon"
              ><img
                :class="hasNotifications ? 'is-white' : 'is-black'"
                src="../assets/bell.svg"
            /></span>
          </button>
          <p
            class="has-text-weight-bold mt-2 is-size-5"
            :class="{ 'has-text-primary': hasNotifications }"
          >
            {{
              hasNotifications ? $t("notifications_enabled") : $t("enable_notifications")
            }}
          </p>
          <p v-if="!hasNotifications" class="is-size-6">{{ $t("with_sms") }}</p>
          <p v-if="hasSession" class="mt-4">
            <button class="button is-danger is-outlined is-rounded"
              @click="$store.dispatch('logout')">{{ $t("logout") }}</button>
          </p>
          <p v-else class="is-size-7" @click="follow">
            {{ hasNotifications ? $t("click_to_disable") : $t("reserved_to_community") }}
          </p>
        </div>
      </div>
    </section>
    <b-modal
      v-model="isSetNotificationModalActive"
      has-modal-card
      full-screen
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-modal
    >
      <template #default>
        <div class="modal-card">
          <section class="modal-card-body">
            <div class="hero set-notification is-fullheight">
              <div class="hero-body">
                <div class="block">
                  <div class="has-text-weight-bold is-size-4 mb-5 label">{{ $t("new_notification") }}</div>
                  <div class="has-text-weight-bold is-size-5 mb-2">
                    <fa icon="bell"></fa>
                    {{ $t("notify_when") }}...
                  </div>
                  <b-field label="Lugares livres">
                    <b-numberinput v-model="minAvailableSeats"></b-numberinput>
                  </b-field>
                  <div class="has-text-weight-bold is-size-5 mt-5 mb-2">
                    <fa icon="sms"></fa>
                    {{ $t("send_message_to") }}...
                  </div>
                  <b-field label="Telefone">
                    <vue-tel-input class="has-background-light" v-model="notificationPhone" v-bind="phoneProps"></vue-tel-input>
                  </b-field>
                </div>
                <div class="block">
                  <b-field class="mt-5">
                    <b-button expanded type="is-primary" size="is-medium"
                      @click="setNotification">{{ $t("set_notification") }}</b-button>
                  </b-field>
                  <b-field>
                    <b-button expanded type="is-light" @click="isSetNotificationModalActive = false">{{ $t("cancel") }}</b-button>
                  </b-field>
                </div>
              </div>
            </div>
          </section>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import ServiceBase from '@/services/ServiceBase'
import Close from '@/components/Close'
import LoadingSpinner from '@/components/LoadingSpinner'

export default {
  components: {
    Close,
    LoadingSpinner
  },
  data () {
    return {
      loading: false,
      notifications: [],
      minAvailableSeats: 1,
      notificationPhone: null,
      isSetNotificationModalActive: false,
      loadDataInterval: null,
      phoneProps: {
        mode: 'international',
        defaultCountry: 'PT',
        disabled: false,
        placeholder: 'Indique o seu telefone',
        required: true,
        enabledCountryCode: false,
        onlyCountries: ['PT'],
        ignoredCountries: [],
        name: 'telephone',
        inputClasses: 'input',
        maxLen: 25
      }
    }
  },
  beforeDestroy () {
    clearInterval(this.loadDataInterval)
  },
  mounted () {
    this.loadData()
    clearInterval(this.loadDataInterval)
    this.loadDataInterval = setInterval(() => {
      this.loadData()
    }, 30000)
  },
  computed: {
    roomId () {
      return this.$store.state.activeRoom || 1
    },
    room () {
      if (this.roomId === 1) {
        return this.$store.state.readingRoom
      } else if (this.roomId === 5) {
        return this.$store.state.attachedRoom
      } else {
        return this.$store.state.multimediaRoom
      }
    },
    hasNotifications () {
      return Boolean(this.$store.state.notifications.length)
    },
    hasSession () {
      return this.$store.state.session.token
    }
  },
  methods: {
    follow () {
      if (!this.hasSession) {
        this.$buefy.dialog.confirm({
          type: 'is-primary',
          title: `${this.$i18n.t('notifications')}`,
          message: `${this.$i18n.t('reserved_sms_description')}`,
          indefinite: true,
          queue: false,
          confirmText: this.$i18n.t('login'),
          cancelText: this.$i18n.t('cancel'),
          canCancel: true,
          onConfirm: () => {
            this.$router.push('/login')
          }
        })
      } else if (this.hasSession && this.hasNotifications) {
        this.$router.push('/notifications')
      } else {
        this.isSetNotificationModalActive = true
      }
    },
    async setNotification () {
      try {
        const notification = await ServiceBase.setNotification(
          this.minAvailableSeats,
          this.room.id,
          this.notificationPhone.replace(/\s+/g, '')
        )
        if (notification) {
          this.loadData()
          this.isSetNotificationModalActive = false
        }
      } catch (error) {
        console.log(error)
      }
    },
    async loadData () {
      this.loading = true
      try {
        await this.$store.dispatch('loadRoomsData')
        await this.$store.dispatch('loadNotifications')
      } finally {
        setTimeout(() => {
          this.loading = false
        }, 1000)
      }
    }
  }
}
</script>
