<template>
  <div class="spinner" :class="size">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: 'is-medium'
    }
  }
}
</script>
